<template>
  <!-- StorageCapacityComponent  -->
  <s-contents-popup
    trigger="click"
    placement="bottom-end"
    :distance="4"
    :offset="[0, 0]"
    :minWidth="320"
    :theme="'studio-storage-capacity'"
  >
    <template #target>
      <s-button size="sm" variant="outline" @click="checkStatus">
        {{ $t('studio.prj_prod.this_prod.product_data.build.home_build_storage') }}
        <span class="inline-flex items-center gap-2 text-xs leading-xs text-on-surface-elevation-2">
          <em class="font-bold">{{ formatStorageDisplay(groupStorage?.storageUsage) }} </em>
          <span class="font-medium">&nbsp;/ {{ convertBytesToGB(groupStorage?.storageVolume) }} GB</span>
        </span>
      </s-button>
    </template>
    <template #header>
      <s-contents-popup-header>
        {{
          $t(
            'studio.prj_prod.this_prod.product_data.build_group_storage_mouseover_total_capacity_msg',
            {
              totalCapa: totalCapacity
            }
          )
        }}
      </s-contents-popup-header>
    </template>
    <template #contents>
      <s-contents-popup-content-body>
        <div class="space-y-16">
          <div class="flex h-16 bg-[var(--stds-glob-color-gray60)] rounded-full overflow-hidden">
            <project-storage-tooltip
              v-for="project in overLimitProjects"
              :key="project.projectId"
              :projectName="project.projectName"
              :storageUsage="formatFileSize(project.storageUsage)"
              :basis="project.basis"
            />
          </div>
          <div class="flex items-center gap-16">
            <p
              class="flex-1 inline-flex items-center gap-2 text-xs leading-xs text-on-surface-elevation-2"
            >
              <em class="font-bold">{{ formatStorageDisplay(groupStorage?.storageUsage) }} </em>
              <span class="font-medium">&nbsp;/
                {{ convertBytesToGB(groupStorage?.storageVolume) }}
                GB</span>
            </p>
            <s-button
              size="sm"
              variant="outline"
              class="shrink-0"
              :isDisabled="isButtonDisabled"
              @click="handleClick"
            >
              {{ requestButtonText }}
            </s-button>
          </div>
        </div>
      </s-contents-popup-content-body>
    </template>
  </s-contents-popup>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import {
  checkGroupStorageRequestApi,
  getGroupStorageApi,
  requestStorageApi
} from '@/apis/build.api';
import ProjectStorageTooltip from '@/components/build/list-table/project-storage-tooltip.vue';
import { showConfirm } from '@/composables/useDialog';
import { ONE_GIGABYTES } from '@/constants/file.const';
import useProductStore from '@/stores/product.store';
import type { GroupStorage, ProjectStorageUsages } from '@/types/build/build.type';
import { convertBytesToGB } from '@/utils/build.util';
import { formatFileSize, formatStorageDisplay } from '@/utils/file.util';

interface ProjectStorageTooltipProps {
  storageUsage: number;
  projectName: string;
  projectId: string;
  basis: number;
}

const route = useRoute();
const { t } = useI18n();

const groupId = route.params.groupId as string;
const productNo = route.params.productId as string;

const isRequesting = ref<boolean>(false);

const groupStorage = ref<GroupStorage>();
const underLimitProjects = ref<ProjectStorageUsages[]>([]);
const overLimitProjects = ref<ProjectStorageTooltipProps[]>([]);

const productStore = useProductStore();
const { isProductWritable } = storeToRefs(productStore);

const canRequestStorage = computed(() => {
  return isProductWritable.value;
});

const isButtonDisabled = computed(() => {
  return isRequesting.value || !canRequestStorage.value;
});

const totalCapacity = computed(() =>
  (
    Math.round(
      convertBytesToGB(
        (groupStorage.value?.storageVolume ?? 0) - (groupStorage.value?.storageUsage ?? 0)
      ) * 10
    ) / 10
  ).toFixed(1)
);

const handleClick = async () => {
  if (!canRequestStorage.value) {
    return;
  }

  const res = await showConfirm<boolean | undefined>({
    align: 'left',
    content: t(
      'studio.prj_prod.this_prod.product_data.build_group_storage_mouseover_request_more_storage_pop_msg2'
    ),
    title: t(
      'studio.prj_prod.this_prod.product_data.build_group_storage_mouseover_request_more_storage_pop_msg1'
    ),
    titleTag: 'h6',
    isCloseButtonShown: true,
    titleRole: 'text3',
    cancelLabel: t('studio.prj_prod.this_prod.product_data.build_group_storage_pop_inquiry_btn'),
    cancelVariant: 'outline',
    confirmLabel: t('studio.prj_prod.this_prod.product_data.build_group_storage_pop_request_btn')
  });

  switch (res) {
    case true:
      try {
        await requestStorageApi(groupId, { productNo });
      } catch (error) {
        // console.log(error);
      }
      break;
    case false:
      window.open('mailto://store.support@smilegate.com');
      break;
    default:
      break;
  }
};

const checkStatus = async () => {
  const requesting = await checkGroupStorageRequestApi(groupId);
  isRequesting.value = requesting ?? false;

  const storage = await getGroupStorageApi(groupId);
  if (storage) {
    processStorageData(storage);
  }
};

const requestButtonText = computed(() => {
  return isRequesting.value
    ? t('studio.prj_prod.this_prod.product_data.build_group_storage_mouseover_request_sent')
    : t(
      'studio.prj_prod.this_prod.product_data.build_group_storage_mouseover_request_more_storage_btn'
    );
});

const processStorageData = (storage: GroupStorage) => {
  groupStorage.value = storage;

  underLimitProjects.value = groupStorage.value.projectStorageUsages.filter(
    (item: ProjectStorageUsages) => item.storageUsage < ONE_GIGABYTES
  );
  const others = underLimitProjects.value.reduce(
    (acc: ProjectStorageTooltipProps, cur: ProjectStorageUsages) => {
      acc.storageUsage += cur.storageUsage;
      return acc;
    },
    {
      storageUsage: 0,
      projectName: t('studio.prj_prod.this_prod.edit_display_countries_regions_other'),
      projectId: 'other-projects',
      basis: 0
    }
  );

  overLimitProjects.value = groupStorage.value.projectStorageUsages
    .filter((item: ProjectStorageUsages) => item.storageUsage >= ONE_GIGABYTES)
    .sort((a: ProjectStorageUsages, b: ProjectStorageUsages) => b.storageUsage - a.storageUsage)
    .concat(others)
    .map((item: ProjectStorageUsages) => {
      return {
        storageUsage: item.storageUsage,
        projectName: item.projectName,
        projectId: item.projectId,
        basis: (item.storageUsage / groupStorage.value!.storageVolume) * 100
      };
    });
};

const init = async () => {
  try {
    const storage = await getGroupStorageApi(groupId);

    if (storage) {
      processStorageData(storage);
    }
  } catch (error) {
    // console.log(error);
  }
};

init();
</script>
