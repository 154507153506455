<template>
  <div class="flex gap-8 items-center absolute -top-[8.5rem] right-0">
    <div class="shrink-0 mr-4">
      <s-button variant="secondary" size="md" class="!min-w-[11.6rem]" @click="openDocumentation">
        {{ $t('studio.prj_prod.this_prod.product_data.build.home_sdk_guide_btn') }}
      </s-button>
    </div>
    <div class="shrink-0">
      <s-button
        variant="primary"
        size="md"
        class="!min-w-[11.6rem]"
        @click="handleUploadBuildClick"
      >
        {{ $t('studio.prj_prod.this_prod.product_data.build.home_upload_btn') }}
      </s-button>
    </div>
  </div>

  <template v-if="dataReady">
    <list-table-wrapper
      :hasProductData="hasProductData"
      :totalElements="totalElements"
      :builds="builds"
      @rowClick="handleRowClick"
      @refresh="handleManualRefresh"
      @pageChange="handlePageChange"
      @sortChange="handleSortChange"
    />
  </template>

  <download-uploader-dialog
    v-if="showUploaderDialog"
    class="max-w-300"
    @close="closeUploaderDialog"
  />
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { onMounted, onUnmounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import { definePageMeta } from '@/.nuxt/imports';
import DownloadUploaderDialog from '@/components/build/dialog/download-uploader-dialog.vue';
import ListTableWrapper from '@/components/build/list-table/list-table-wrapper.vue';
import { useApp } from '@/composables/useApp';
import { hasActiveDialog } from '@/composables/useDialog';
import { useDownloadUploaderDialog } from '@/composables/useDownloadUploaderDialog';
import useUriHandler from '@/composables/useUriHandler';
import { BUILD_KEY_NAME_COLUMN, PAGE_SIZE_BUILD } from '@/constants/build.const';
import { SORT_DIRECTION, SORT_VALUE } from '@/constants/common.const';
import { PRODUCT_BUILD_PAGE_URL } from '@/constants/url.const';
import { useAppStore } from '@/stores/app.store';
import { useBuildStore } from '@/stores/build.store';
import useProductStore from '@/stores/product.store';
import { useUserStore } from '@/stores/user.store';
import type { Build, FetchProductBuildParams } from '@/types/build/build.type';
import type { ColumnSorting } from '@/types/table.type';
import { handleUploadBuild } from '@/utils/build.util';
import { redirectTo } from '@/utils/common.util';

definePageMeta({
  middleware: ['handle-product-data-middleware'],
  showProductKey: true
});

const { locale } = useI18n();
const route = useRoute();
const { checkProductPermission, getDocsUrl } = useApp();

// Store setup
const buildStore = useBuildStore();
const userStore = useUserStore();
const productStore = useProductStore();
const appStore = useAppStore();

// Store refs
const { accessToken, selectedGroupId } = storeToRefs(userStore);
const { product } = storeToRefs(productStore);
const { gdsInfo } = storeToRefs(appStore);
const { builds, totalElements } = storeToRefs(buildStore);

// Local refs
const dataReady = ref(false);
const hasProductData = ref(false);
const refreshInterval = ref<ReturnType<typeof setInterval> | null>(null);

// Use the new utility function
const { showUploaderDialog, setupVisibilityListeners, closeUploaderDialog } =
  useDownloadUploaderDialog();

// Template data
const { isError } = useUriHandler();

const gameId = product.value?.gameId || '';
const projectId = route.params.projectId as string;

const fetchData = async (params?: Partial<FetchProductBuildParams>) => {
  if (!dataReady.value) {
    dataReady.value = false;
  }

  const defaultParams: FetchProductBuildParams = {
    teamId: selectedGroupId.value,
    projectId,
    gameId,
    sort: REQUEST_SORT.CREATED_AT,
    direction: SORT_DIRECTION.DESC,
    searchValue: '',
    page: 1,
    size: PAGE_SIZE_BUILD
  };

  await buildStore.fetchBuilds({ ...defaultParams, ...params });
  hasProductData.value = builds.value.length > 0;
  dataReady.value = true;
};

const handleRowClick = async (build: Build) => {
  const buildId = build.buildId || '';

  await redirectTo(`${PRODUCT_BUILD_PAGE_URL}/${buildId}`);
};

const openDocumentation = () => {
  const url = getDocsUrl();
  window.open(url, '_blank');
};

const handleUploadBuildClick = async () => {
  if (!(await checkProductPermission())) {
    return;
  }
  if (!selectedGroupId.value && gameId) {
    return;
  }

  handleUploadBuild({
    selectedGroupId: selectedGroupId.value,
    accessToken: accessToken.value,
    gameId,
    langCd: locale.value,
    nationCd: gdsInfo.value.nation
  });
};

watch(isError, async (newValue: boolean) => {
  if (newValue && !hasActiveDialog.value) {
    showUploaderDialog.value = true;
  }
});

const startAutoRefresh = () => {
  // Clear existing interval if any
  stopAutoRefresh();

  // Set new interval - 60000ms = 1 minute
  refreshInterval.value = setInterval(async () => {
    await fetchData(); // Ensure we await the fetch
  }, 60000);
};

const stopAutoRefresh = () => {
  if (refreshInterval.value) {
    clearInterval(refreshInterval.value);
    refreshInterval.value = null;
  }
};

const handleManualRefresh = async () => {
  await fetchData();
  startAutoRefresh();
};

const handlePageChange = async (page: number) => {
  await fetchData({ page });
};

const REQUEST_SORT = {
  CREATED_AT: 'CREATED_AT',
  BUILD_ID: 'BUILD_ID',
  CAPACITY: 'CAPACITY'
};

const handleSortChange = async (value: ColumnSorting) => {
  let sort = REQUEST_SORT.BUILD_ID;
  let direction = SORT_DIRECTION.DESC;

  if (value.sortValue !== SORT_VALUE.NONE) {
    switch (value.sortKey) {
      case BUILD_KEY_NAME_COLUMN.SIZE:
        sort = REQUEST_SORT.CAPACITY;
        break;
      case BUILD_KEY_NAME_COLUMN.DATE:
        sort = REQUEST_SORT.CREATED_AT;
        break;
      case BUILD_KEY_NAME_COLUMN.BUILD_ID:
        sort = REQUEST_SORT.BUILD_ID;
        break;
      default:
        sort = value.sortKey;
    }
    direction = value.sortValue === SORT_VALUE.DESC ? SORT_DIRECTION.DESC : SORT_DIRECTION.ASC;
  }

  await fetchData({
    sort,
    direction,
    page: 1 // Reset page when sort changes
  });
};

onMounted(() => {
  fetchData();
  startAutoRefresh();
  const cleanup = setupVisibilityListeners();

  // Store cleanup function for onUnmounted
  onUnmounted(() => {
    stopAutoRefresh();
    cleanup(); // Call the cleanup function returned by setupVisibilityListeners
  });
});
</script>
