<template>
  <list-build
    v-if="hasProductData"
    :totalElements="totalElements"
    :builds="builds"
    @rowClick="onRowClick($event)"
    @refresh="handleManualRefresh"
    @pageChange="handlePageChange"
    @sortChange="handleSortChange"
  />
  <alert-no-build v-else @refresh="handleManualRefresh" />
</template>
<script lang="ts" setup>
import AlertNoBuild from '@/components/build/alert-no-build.vue';
import ListBuild from '@/components/build/list-build.vue';
import type { Build } from '@/types/build/build.type';
import type { ColumnSorting } from '@/types/table.type';

defineProps<{
  hasProductData: boolean;
  totalElements: number;
  builds: Build[];
}>();

// eslint-disable-next-line func-call-spacing
const emit = defineEmits<{
  (e: 'rowClick', build: Build): void;
  (e: 'refresh'): void;
  (e: 'pageChange', page: number): void;
  (e: 'sortChange', value: ColumnSorting): void;
}>();

const onRowClick = (build: Build) => {
  emit('rowClick', build);
};

const handleManualRefresh = () => {
  emit('refresh');
};

const handlePageChange = (page: number) => {
  emit('pageChange', page);
};

const handleSortChange = (value: ColumnSorting) => {
  emit('sortChange', value);
};
</script>
