<template>
  <st-box class="pl-40 pr-40 pt-24 pb-40">
    <div
      class="flex gap-4 items-center justify-end cursor-pointer py-[0.7rem]"
      @click="handleRefresh"
    >
      <button type="button" class="text-on-surface-elevation-2 disabled:text-disabled-variant-1">
        <s-icon
          icon="ic-v2-control-refresh-line"
          size="2xl"
          class="flex text-gray880"
          srOnly="새로고침"
        />
      </button>
      <safe-html
        tag="p"
        class="text-xs leading-xs font-medium text-center text-gray880"
        :html="$t('studio.build_mgmt.list_reload_btn')"
      />
    </div>
    <div class="pb-8 px-[1rem]">
      <div class="pt-32 flex items-center justify-center gap-20 flex-col">
        <img src="@/assets/images/build/25-1-2-img.svg" alt="" />

        <div class="space-y-8">
          <safe-html
            tag="p"
            class="text-3xl leading-2xl font-bold text-on-surface-elevation-1 text-center"
            :html="$t('studio.prj_prod.this_prod.product_data.build.home_guide1')"
          />
          <safe-html
            tag="p"
            class="text-text3 leading-text3 font-text3 text-center text-on-surface-elevation-3"
            :html="$t('studio.prj_prod.this_prod.product_data.build.home_guide1_1')"
          />
        </div>
      </div>
      <div class="mt-[6rem] flex items-start gap-40 px-20">
        <div class="flex flex-col gap-[1rem] flex-1">
          <s-icon
            size="3xl"
            icon="ic-v2-navigation-link-fill"
            class="flex text-on-surface-elevation-3"
          />
          <p class="text-sm font-regular text-on-surface-elevation-3 leading-md">
            {{ $t('studio.prj_prod.this_prod.product_data.build.home_guide2') }}
          </p>
        </div>
        <div class="flex flex-col gap-[1rem] flex-1">
          <s-icon
            size="3xl"
            icon="ic-v2-hardware-desktop-fill"
            class="flex text-on-surface-elevation-3"
          />
          <p class="text-sm font-regular text-on-surface-elevation-3 leading-md">
            {{ $t('studio.prj_prod.this_prod.product_data.build.home_guide3') }}
          </p>
        </div>
      </div>
    </div>
  </st-box>
</template>
<script setup lang="ts">
import SafeHtml from '@/components/common/safe-html.vue';
import StBox from '@/components/common/st-box.vue';
import { useRefreshHandler } from '@/composables/useRefreshHandler';

// eslint-disable-next-line func-call-spacing
const emit = defineEmits<{
  (e: 'refresh'): void;
}>();

const { handleRefresh } = useRefreshHandler(() => emit('refresh'));
</script>
