<template>
  <div @click.stop>
    <!-- UploadStatusComponent -->

    <!-- Upload IN PROGRESS -->
    <!-- CONDITION:
    upload_status === 'IN_PROGRESS' -->
    <span
      v-if="status === UPLOAD_STATUS_MESSAGE.IN_PROGRESS"
      class="inline-flex items-center gap-2 py-[.3rem] text-xs leading-xs text-on-surface-elevation-2"
    >
      {{ $t('studio.build_mgmt.build_upload_stt0_in_progress') }}
    </span>

    <!-- Upload stopped: possible resume -->
    <!-- CONDITION:
    upload_status === 'STOPPED' -->
    <s-contents-popup
      v-else-if="status === UPLOAD_STATUS_MESSAGE.UPLOAD_STOPPED"
      trigger="click"
      placement="bottom-end"
      :distance="4"
      :offset="[0, 0]"
      :minWidth="312"
    >
      <template #target>
        <button
          type="button"
          class="inline-flex items-center gap-2 px-[.6rem] py-[.3rem] bg-[#F0F2F8] rounded-[.6rem] text-[#909298]"
        >
          <span class="text-xs leading-xs">
            {{
              $t('studio.prj_prod.this_prod.product_data.build.home_upload_status_paused_pop1_msg1')
            }}
          </span>
          <s-icon size="xs" icon="ic-v2-state-info-circle-line" />
        </button>
      </template>
      <template #contents>
        <div class="px-24 py-20">
          <h1 class="mb-8 text-md font-bold leading-sm text-on-surface-elevation-2">
            {{
              isPermissionDelete(build.memberNo, userInfo?.memberNo)
                ? $t(
                  'studio.prj_prod.this_prod.product_data.build.uploader_step1_incompleted_bld_exist_popup_title'
                )
                : $t(
                  'studio.prj_prod.this_prod.product_data.build.home_upload_status_paused_pop2_msg1'
                )
            }}
          </h1>
          <safe-html
            v-if="isPermissionDelete(build.memberNo, userInfo?.memberNo)"
            tag="p"
            class="text-md leading-lg text-on-surface-elevation-2"
            :html="
              $t('studio.prj_prod.this_prod.product_data.build.home_upload_status_paused_pop1_msg2')
            "
          />
          <safe-html
            v-else
            tag="p"
            class="text-md leading-lg text-on-surface-elevation-2"
            :html="
              $t('studio.prj_prod.this_prod.product_data.build.home_upload_status_paused_pop2_msg2')
            "
          />
          <div class="mt-8 space-x-8">
            <s-button
              v-if="canDelete"
              size="sm"
              variant="outline"
              class="text-tint-red-a400"
              @click="deleteBuild"
            >
              {{
                $t(
                  'studio.prj_prod.this_prod.product_data.build.home_upload_status_paused_pop1_delete_build_btn'
                )
              }}
            </s-button>
            <s-button v-if="canUpload" size="sm" variant="primary" @click="handleUploadBuildClick">
              {{
                $t(
                  'studio.prj_prod.this_prod.product_data.build.home_upload_status_paused_pop1_resume_btn'
                )
              }}
            </s-button>
          </div>
        </div>
      </template>
    </s-contents-popup>

    <!-- Upload failed -->
    <!-- CONDITION:
  upload_status === 'FAIL' -->
    <s-contents-popup
      v-else-if="status === UPLOAD_STATUS_MESSAGE.UPLOAD_FAILED"
      trigger="click"
      placement="bottom-end"
      :distance="4"
      :offset="[0, 0]"
      :minWidth="312"
    >
      <template #target>
        <button
          type="button"
          class="inline-flex items-center gap-2 px-[.6rem] py-[.3rem] bg-[#FFEDED] rounded-[.6rem] text-[#E84C4C]"
        >
          <span class="text-xs leading-xs">
            {{
              $t('studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg')
            }}
          </span>
          <s-icon size="xs" icon="ic-v2-state-info-circle-line" />
        </button>
      </template>
      <template #contents>
        <div class="px-24 py-20 space-y-8">
          <h1 class="mb-8 text-md font-bold leading-sm text-on-surface-elevation-2">
            {{
              $t('studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg')
            }}
          </h1>
          <safe-html
            tag="p"
            class="text-md leading-lg text-on-surface-elevation-2"
            :html="$t(getErrorMessage(build.errorCode, ''))"
          />
          <p class="mt-4 text-xs font-medium leading-xs text-system-red">
            [{{ build.errorCode || '' }}]
          </p>
        </div>
      </template>
    </s-contents-popup>

    <!-- Reviewing(in-review) -->
    <!-- CONDITION:
    build.status !== 'FAIL' & !== 'SUCCESS' && vaccine.status !== 'FAIL' & !== 'SUCCESS'  -->
    <s-contents-popup
      v-else-if="status === UPLOAD_STATUS_MESSAGE.REVIEWING"
      trigger="click"
      placement="bottom-end"
      :distance="4"
      :offset="[0, 0]"
      :minWidth="312"
    >
      <template #target>
        <button
          type="button"
          class="inline-flex items-center gap-2 px-[.6rem] py-[.3rem] bg-[#F2F6FF] rounded-[.6rem] text-[#5F94BA]"
        >
          <span class="text-xs leading-xs">
            {{ $t('studio.build_mgmt.build_upload_stt3_inspecting') }}
          </span>
          <s-icon size="xs" icon="ic-v2-state-info-circle-line" />
        </button>
      </template>
      <template #contents>
        <div class="px-24 py-20 space-y-8">
          <dl class="flex flex-col gap-4">
            <dt class="text-md font-bold leading-sm text-on-surface-elevation-2">
              [{{ t(getMessageBuildCreateAndSuccess.buildTitle) }}]
            </dt>
            <dd
              :class="[
                'text-md leading-lg text-on-surface-elevation-2',
                getMessageBuildCreateAndSuccess.content.className
              ]"
            >
              {{ t(getMessageBuildCreateAndSuccess.content.message) }}
              <span
                v-if="
                  build.build?.status !== UPLOAD_STATUS_MESSAGE.FAIL &&
                    build.build?.status !== UPLOAD_STATUS_MESSAGE.SUCCESS
                "
                class="text-xs font-medium leading-xs text-brand-primary align-text-bottom"
              >
                &nbsp;
                {{
                  $t('studio.build_mgmt.build_steps_count', {
                    currentStep: getMessageBuildCreateAndSuccess.content.displayStep,
                    totalStep: getMessageBuildCreateAndSuccess.content.totalSteps + '&nbsp;'
                  })
                }}
              </span>
            </dd>
          </dl>
          <dl class="flex flex-col gap-4">
            <dt class="text-md font-bold leading-sm text-on-surface-elevation-2">
              [{{ t(getMessageSecurityInspectionCreateAndSuccess.securityTitle) }}]
            </dt>
            <dd
              :class="[
                'text-md leading-lg text-on-surface-elevation-2',
                getMessageSecurityInspectionCreateAndSuccess.content.className
              ]"
            >
              <safe-html :html="$t(getMessageSecurityInspectionCreateAndSuccess.content.message)" />
              <span
                v-if="
                  build.vaccine?.status !== UPLOAD_STATUS_MESSAGE.FAIL &&
                    build.vaccine?.status !== UPLOAD_STATUS_MESSAGE.SUCCESS
                "
                class="text-xs font-medium leading-xs text-brand-primary align-text-bottom"
              >
                &nbsp;
                {{
                  $t('studio.build_mgmt.build_steps_count', {
                    currentStep: getMessageSecurityInspectionCreateAndSuccess.content.displayStep,
                    totalStep:
                      getMessageSecurityInspectionCreateAndSuccess.content.totalSteps + '&nbsp;'
                  })
                }}
              </span>
            </dd>
          </dl>
        </div>
      </template>
    </s-contents-popup>

    <!-- Build creation failure - Popup3 -->
    <!-- CONDITION:
    build.build.status === 'FAIL' && build.vaccine.status !== 'FAIL' -->
    <s-contents-popup
      v-else-if="status === UPLOAD_STATUS_MESSAGE.BUILD_CREATION_FAILED"
      trigger="click"
      placement="bottom-end"
      :distance="4"
      :offset="[0, 0]"
      :minWidth="312"
    >
      <template #target>
        <button
          type="button"
          class="inline-flex items-center gap-2 px-[.6rem] py-[.3rem] bg-[#FFEDED] rounded-[.6rem] text-[#E84C4C]"
        >
          <span class="text-xs leading-xs">
            {{ $t('studio.build_mgmt.build_upload_stt4_build_gen_failed') }}
          </span>
          <s-icon size="xs" icon="ic-v2-state-info-circle-line" />
        </button>
      </template>
      <template #contents>
        <div class="px-24 py-20 space-y-8">
          <dl class="flex flex-col gap-4">
            <dt class="text-md font-bold leading-sm text-on-surface-elevation-2">
              [{{
                $t(
                  'studio.prj_prod.this_prod.product_data.build.home_upload_status_testing_pop_build_status_failed_msg'
                )
              }}]
            </dt>
            <safe-html
              tag="dd"
              :class="'text-md leading-lg text-on-surface-elevation-2'"
              :html="
                $t(
                  getErrorMessage(build.build?.errorCode, {
                    context: 'build',
                    defaultValue: ''
                  }),
                  getErrorMessageVariables(build.build?.errorCode)
                )
              "
            />
            <p class="text-xs font-medium leading-xs text-system-red">
              [{{ build.build?.errorCode || '' }}]
            </p>
          </dl>
          <dl class="flex flex-col gap-4">
            <dt class="text-md font-bold leading-sm text-on-surface-elevation-2">
              [{{ t(getMessageSecurityInspectionCreateAndSuccess.securityTitle) }}]
            </dt>
            <dd
              :class="[
                'text-md leading-lg text-on-surface-elevation-2',
                getMessageSecurityInspectionCreateAndSuccess.content.className
              ]"
            >
              <safe-html :html="$t(getMessageSecurityInspectionCreateAndSuccess.content.message)" />
              <span
                v-if="
                  build.vaccine?.status !== UPLOAD_STATUS_MESSAGE.FAIL &&
                    build.vaccine?.status !== UPLOAD_STATUS_MESSAGE.SUCCESS
                "
                class="text-xs font-medium leading-xs text-brand-primary align-text-bottom"
              >
                &nbsp;
                {{
                  $t('studio.build_mgmt.build_steps_count', {
                    currentStep: getMessageSecurityInspectionCreateAndSuccess.content.displayStep,
                    totalStep:
                      getMessageSecurityInspectionCreateAndSuccess.content.totalSteps + '&nbsp;'
                  })
                }}
              </span>
            </dd>
          </dl>
        </div>
      </template>
    </s-contents-popup>

    <!-- Security failed - Popup 4 -->
    <!-- CONDITION:
    build.build.status !== 'FAIL' && build.vaccine.status === 'FAIL' && build.reviewStatus === null -->
    <s-contents-popup
      v-else-if="status === UPLOAD_STATUS_MESSAGE.SECURITY_INSPECTION_FAILED"
      trigger="click"
      placement="bottom-end"
      :distance="4"
      :offset="[0, 0]"
      :minWidth="312"
    >
      <template #target>
        <button
          type="button"
          class="inline-flex items-center gap-2 px-[.6rem] py-[.3rem] bg-[#FFEDED] rounded-[.6rem] text-[#E84C4C]"
        >
          <span class="text-xs leading-xs">
            {{ $t('studio.build_mgmt.build_upload_stt5_security_inspection_failed') }}
          </span>
          <s-icon size="xs" icon="ic-v2-state-info-circle-line" />
        </button>
      </template>
      <template #contents>
        <div class="px-24 py-20 space-y-8">
          <dl class="flex flex-col gap-4">
            <dt class="text-md font-bold leading-sm text-on-surface-elevation-2">
              [{{ t(getMessageBuildCreateAndSuccess.buildTitle) }}]
            </dt>
            <dd
              :class="[
                'text-md leading-lg text-on-surface-elevation-2',
                getMessageBuildCreateAndSuccess.content.className
              ]"
            >
              {{ t(getMessageBuildCreateAndSuccess.content.message) }}
              <span
                v-if="
                  build.build?.status !== UPLOAD_STATUS_MESSAGE.FAIL &&
                    build.build?.status !== UPLOAD_STATUS_MESSAGE.SUCCESS
                "
                class="text-xs font-medium leading-xs text-brand-primary align-text-bottom"
              >
                &nbsp;
                {{
                  $t('studio.build_mgmt.build_steps_count', {
                    currentStep: getMessageBuildCreateAndSuccess.content.displayStep,
                    totalStep: getMessageBuildCreateAndSuccess.content.totalSteps + '&nbsp;'
                  })
                }}
              </span>
            </dd>
          </dl>
          <dl class="flex flex-col gap-4">
            <dt class="text-md font-bold leading-sm text-on-surface-elevation-2">
              [{{
                $t(
                  'studio.prj_prod.this_prod.product_data.build.home_upload_status_testing_pop_security_test_failed_msg'
                )
              }}]
            </dt>
            <safe-html
              tag="p"
              class="text-md leading-lg text-on-surface-elevation-2"
              :html="
                $t(
                  getErrorMessage(
                    build?.vaccine?.errorCode,
                    'studio.build_mgmt.case_drm_maker.unknown_error_error_msg'
                  ),
                  getErrorMessageVariables(build?.vaccine?.errorCode)
                )
              "
            />

            <div class="flex flex-row justify-between">
              <p class="text-xs font-medium leading-xs text-system-red">
                [{{ build?.vaccine?.errorCode || '' }}]
              </p>
              <button
                v-if="isSecurityInspectionFailError"
                class="text-xs text-on-surface-elevation-3"
                @click="toggleDetails"
              >
                {{
                  $t(
                    'studio.prj_prod.this_prod.product_data.build.security_test_failed_learn_more_btn'
                  )
                }}
                <s-icon
                  size="xs"
                  :icon="
                    showDetails ? 'ic-v2-control-arrow-up-line' : 'ic-v2-control-arrow-down-line'
                  "
                  class="ml-4 text-on-surface-elevation-3"
                />
              </button>
            </div>
            <div v-if="showDetails" class="my-8 bg-[var(--stds-glob-color-gray40)] rounded-[.8rem]">
              <div
                class="text-xs text-on-surface-elevation-2 py-8 px-12 max-h-[14.7rem] studio-scrollbar-4"
              >
                <div v-for="key in errorDetailKeys" :key="key" class="mb-4 last:mb-0">
                  <s-tooltip
                    :content="key"
                    :duration="0"
                    placement="bottom-start"
                    trigger="mouseenter focus"
                    :theme="'studio-tooltip'"
                    class="inline-block max-w-[26.4rem] truncate text-xs"
                  >
                    <template #target>
                      {{ key }}
                    </template>
                  </s-tooltip>
                </div>
              </div>
            </div>
          </dl>
          <s-button
            v-if="canOpenSecurityManual"
            size="sm"
            variant="primary"
            @click="openSecurityManual"
          >
            {{
              $t(
                'studio.prj_prod.this_prod.product_data.build.security_test_failed_manual_test_request_btn'
              )
            }}
          </s-button>
          <span
            v-else
            class="self-start inline-flex px-[.6rem] py-[.3rem] text-xs leading-xs bg-[#F0F2F8] rounded-[.6rem] text-[#909298]"
          >
            {{
              $t(
                'studio.prj_prod.this_prod.product_data.build.security_test_failed_manual_test_request_btn'
              )
            }}
          </span>
        </div>
      </template>
    </s-contents-popup>

    <!-- Security review failed: Manual security review guidance tooltip - Popup 5 -->
    <!-- CONDITION:
    build.build.status !== 'FAIL' && build.vaccine.status === 'FAIL' && build.reviewStatus === 'REQUEST' -->
    <s-contents-popup
      v-else-if="status === UPLOAD_STATUS_MESSAGE.SECURITY_INSPECTION_REVIEWING"
      trigger="click"
      placement="bottom-end"
      :distance="4"
      :offset="[0, 0]"
      :minWidth="312"
    >
      <template #target>
        <button
          type="button"
          class="inline-flex items-center gap-2 px-[.6rem] py-[.3rem] bg-[#FFEDED] rounded-[.6rem] text-[#E84C4C]"
        >
          <span class="text-xs leading-xs">
            {{ $t('studio.build_mgmt.build_upload_stt5_security_inspection_failed') }}
          </span>
          <s-icon size="xs" icon="ic-v2-state-info-circle-line" />
        </button>
      </template>
      <template #contents>
        <div class="px-24 py-20 space-y-8">
          <dl class="flex flex-col gap-4">
            <dt class="text-md font-bold leading-sm text-on-surface-elevation-2">
              [{{ t(getMessageBuildCreateAndSuccess.buildTitle) }}]
            </dt>
            <dd
              :class="[
                'text-md leading-lg text-on-surface-elevation-2',
                getMessageBuildCreateAndSuccess.content.className
              ]"
            >
              {{ t(getMessageBuildCreateAndSuccess.content.message) }}
              <span
                v-if="
                  build.build?.status !== UPLOAD_STATUS_MESSAGE.FAIL &&
                    build.build?.status !== UPLOAD_STATUS_MESSAGE.SUCCESS
                "
                class="text-xs font-medium leading-xs text-brand-primary align-text-bottom"
              >
                &nbsp;
                {{
                  $t('studio.build_mgmt.build_steps_count', {
                    currentStep: getMessageBuildCreateAndSuccess.content.displayStep,
                    totalStep: getMessageBuildCreateAndSuccess.content.totalSteps + '&nbsp;'
                  })
                }}
              </span>
            </dd>
          </dl>
          <dl class="flex flex-col gap-4">
            <dt class="text-md font-bold leading-sm text-on-surface-elevation-2">
              [{{
                $t(
                  'studio.prj_prod.this_prod.product_data.build.home_upload_status_testing_pop_security_test_failed_msg'
                )
              }}]
            </dt>
            <safe-html
              tag="p"
              class="text-md leading-lg text-on-surface-elevation-2"
              :html="
                $t(
                  getErrorMessage(
                    build?.vaccine?.errorCode,
                    'studio.build_mgmt.case_drm_maker.unknown_error_error_msg'
                  ),
                  getErrorMessageVariables(build?.vaccine?.errorCode)
                )
              "
            />
            <div class="flex flex-row justify-between">
              <p class="text-xs font-medium leading-xs text-system-red">
                [{{ build?.vaccine?.errorCode || '' }}]
              </p>
              <button
                v-if="isSecurityInspectionFailError"
                class="text-xs text-on-surface-elevation-3"
                @click="toggleDetails"
              >
                {{
                  $t(
                    'studio.prj_prod.this_prod.product_data.build.security_test_failed_learn_more_btn'
                  )
                }}
                <s-icon
                  size="xs"
                  :icon="
                    showDetails ? 'ic-v2-control-arrow-up-line' : 'ic-v2-control-arrow-down-line'
                  "
                  class="ml-4 text-on-surface-elevation-3"
                />
              </button>
            </div>
            <div v-if="showDetails" class="my-8 bg-[var(--stds-glob-color-gray40)] rounded-[.8rem]">
              <div
                class="text-xs text-on-surface-elevation-2 py-8 px-12 max-h-[14.7rem] studio-scrollbar-4"
              >
                <div v-for="key in errorDetailKeys" :key="key" class="mb-4 last:mb-0">
                  <s-tooltip
                    :content="key"
                    :duration="0"
                    placement="bottom-start"
                    trigger="mouseenter focus"
                    :theme="'studio-tooltip'"
                    class="inline-block max-w-[26.4rem] truncate text-xs"
                  >
                    <template #target>
                      {{ key }}
                    </template>
                  </s-tooltip>
                </div>
              </div>
            </div>

            <span
              class="self-start inline-flex px-[.6rem] py-[.3rem] text-xs leading-xs bg-[#F0F2F8] rounded-[.6rem] text-[#909298] mt-8"
            >
              {{
                $t(
                  'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_manual_test_requested_msg'
                )
              }}
            </span>
          </dl>
        </div>
      </template>
    </s-contents-popup>

    <!-- Failure - Popup 6 -->
    <!-- CONDITION:
    build.build.status === 'FAIL' && build.vaccine.status === 'FAIL' -->
    <s-contents-popup
      v-else-if="status === UPLOAD_STATUS_MESSAGE.FAILURE"
      trigger="click"
      placement="bottom-end"
      :distance="4"
      :offset="[0, 0]"
      :minWidth="312"
    >
      <template #target>
        <button
          type="button"
          class="inline-flex items-center gap-2 px-[.6rem] py-[.3rem] bg-[#FFEDED] rounded-[.6rem] text-[#E84C4C]"
        >
          <span class="text-xs leading-xs">
            {{ $t('studio.build_mgmt.build_upload_stt6_failed') }}
          </span>
          <s-icon size="xs" icon="ic-v2-state-info-circle-line" />
        </button>
      </template>
      <template #contents>
        <div class="px-24 py-20 space-y-8">
          <dl class="flex flex-col gap-4">
            <template v-if="build.build?.status === UPLOAD_STATUS_MESSAGE.FAIL">
              <dt class="text-md font-bold leading-sm text-on-surface-elevation-2">
                [{{
                  $t(
                    'studio.prj_prod.this_prod.product_data.build.home_upload_status_testing_pop_build_status_failed_msg'
                  )
                }}]
              </dt>
              <safe-html
                tag="p"
                class="text-md leading-lg text-on-surface-elevation-2"
                :html="
                  $t(
                    getErrorMessage(build?.build?.errorCode, {
                      context: 'build',
                      defaultValue: ''
                    }),
                    getErrorMessageVariables(build?.build?.errorCode)
                  )
                "
              />
              <p class="text-xs font-medium leading-xs text-system-red">
                [{{ build?.build?.errorCode || '' }}]
              </p>
            </template>
            <template v-else>
              <dt class="text-md font-bold leading-sm text-on-surface-elevation-2">
                [{{ t(getMessageBuildCreateAndSuccess.buildTitle) }}]
              </dt>
              <dd
                :class="[
                  'text-md leading-lg text-on-surface-elevation-2',
                  getMessageBuildCreateAndSuccess.content.className
                ]"
              >
                {{ t(getMessageBuildCreateAndSuccess.content.message) }}
                <span
                  v-if="
                    build.build?.status !== UPLOAD_STATUS_MESSAGE.FAIL &&
                      build.build?.status !== UPLOAD_STATUS_MESSAGE.SUCCESS
                  "
                  class="text-xs font-medium leading-xs text-brand-primary align-text-bottom"
                >
                &nbsp;
                  {{
                    $t('studio.build_mgmt.build_steps_count', {
                      currentStep: getMessageBuildCreateAndSuccess.content.displayStep,
                      totalStep: getMessageBuildCreateAndSuccess.content.totalSteps + '&nbsp;'
                    })
                  }}
                </span>
              </dd>
            </template>
          </dl>

          <dl class="flex flex-col gap-4">
            <dt class="text-md font-bold leading-sm text-on-surface-elevation-2">
              [{{
                $t(
                  'studio.prj_prod.this_prod.product_data.build.home_upload_status_testing_pop_security_test_failed_msg'
                )
              }}]
            </dt>
            <safe-html
              tag="p"
              class="text-md leading-lg text-on-surface-elevation-2"
              :html="
                $t(
                  getErrorMessage(
                    build?.vaccine?.errorCode,
                    'studio.build_mgmt.case_drm_maker.unknown_error_error_msg'
                  ),
                  getErrorMessageVariables(build?.vaccine?.errorCode)
                )
              "
            />
            <div class="flex flex-row justify-between">
              <p class="text-xs font-medium leading-xs text-system-red">
                [{{ build?.vaccine?.errorCode || '' }}]
              </p>
              <button
                v-if="isSecurityInspectionFailError"
                class="text-xs text-on-surface-elevation-3"
                @click="toggleDetails"
              >
                {{
                  $t(
                    'studio.prj_prod.this_prod.product_data.build.security_test_failed_learn_more_btn'
                  )
                }}
                <s-icon
                  size="xs"
                  :icon="
                    showDetails ? 'ic-v2-control-arrow-up-line' : 'ic-v2-control-arrow-down-line'
                  "
                  class="ml-4 text-on-surface-elevation-3"
                />
              </button>
            </div>
            <div v-if="showDetails" class="my-8 bg-[var(--stds-glob-color-gray40)] rounded-[.8rem]">
              <div
                class="text-xs text-on-surface-elevation-2 py-8 px-12 max-h-[14.7rem] studio-scrollbar-4"
              >
                <div v-for="key in errorDetailKeys" :key="key" class="mb-4 last:mb-0">
                  <s-tooltip
                    :content="key"
                    :duration="0"
                    placement="bottom-start"
                    trigger="mouseenter focus"
                    :theme="'studio-tooltip'"
                    class="inline-block max-w-[26.4rem] truncate text-xs"
                  >
                    <template #target>
                      {{ key }}
                    </template>
                  </s-tooltip>
                </div>
              </div>
            </div>

            <span
              class="self-start inline-flex px-[.6rem] py-[.3rem] text-xs leading-xs bg-[#FFEDED] rounded-[.6rem] text-[#E84C4C]"
            >
              {{
                $t(
                  'studio.prj_prod.this_prod.product_data.build.details_basic_info_upload_status_request_manual_test_rejected_stt'
                )
              }}
            </span>
            <p
              class="text-sm leading-md text-on-surface-elevation-2 break-words break-all overflow-hidden whitespace-normal max-w-full"
            >
              {{ build.memo }}
            </p>
          </dl>
        </div>
      </template>
    </s-contents-popup>

    <!-- COMPLETE -->
    <!-- CONDITION:
  build.build.status === 'SUCCESS' && build.vaccine.status === 'SUCCESS' || vaccine.build === 'FAIL' && review_status == "APPROVE"  -->
    <span
      v-else-if="status === UPLOAD_STATUS_MESSAGE.SUCCESS"
      class="inline-flex items-center gap-2 py-[.3rem] text-xs leading-xs text-on-surface-elevation-2"
    >
      {{ $t('studio.prj_prod.this_prod.product_data.build.uploader_step2_finish_btn') }}
    </span>

    <s-toast-area class="md:bottom-0 bottom-48" location="center" />

    <security-manual
      v-if="showSecurityManual"
      :buildId="props.build.buildId"
      :gameId="props.build.gameId"
      :errorCodeSecurity="props.build.vaccine?.errorCode"
      :errorDetailSecurity="props.build.vaccine?.errorDetail"
      @close="showSecurityManual = false"
      @submitSuccess="$emit('refresh')"
    />
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import ConfirmDeleteBuild from '@/components/build/dialog/confirm-delete-build.vue';
import SecurityManual from '@/components/build/list-table/security-manual.vue';
import SafeHtml from '@/components/common/safe-html.vue';
import { useApp } from '@/composables/useApp';
import { showDialog } from '@/composables/useDialog';
import {
  ARRAY_ERROR_CODE_SECURITY_INSPECTION_FAIL,
  UPLOAD_STATUS_MESSAGE
} from '@/constants/build.const';
import { useAppStore } from '@/stores/app.store';
import useProductStore from '@/stores/product.store';
import { useUserStore } from '@/stores/user.store';
import type { Build, UploadStatusMessage } from '@/types/build/build.type';
import {
  getErrorMessage,
  getErrorMessageVariables,
  handleUploadBuild,
  isPermissionDelete,
  renderValueMessageBuild,
  renderValueMessageSecurity
} from '@/utils/build.util';

const { checkProductPermission } = useApp();

const props = defineProps<{
  status: UploadStatusMessage;
  build: Build;
}>();

defineEmits(['refresh']);

const { t, locale } = useI18n();
const userStore = useUserStore();
const { gdsInfo } = storeToRefs(useAppStore());
const productStore = useProductStore();

const { userInfo, selectedGroupId, accessToken } = storeToRefs(userStore);
const { isProductWritable } = storeToRefs(productStore);

const showSecurityManual = ref(false);
const showDetails = ref(false);

const canUpload = computed(() => {
  return (
    isProductWritable.value && isPermissionDelete(props.build.memberNo, userInfo.value?.memberNo)
  );
});

const canDelete = computed(() => {
  return isProductWritable.value;
});

const errorDetailKeys = computed(() => {
  if (props.build?.vaccine?.errorDetail) {
    return Object.keys(props.build.vaccine.errorDetail);
  }
  return [];
});

const isSecurityInspectionFailError = computed(() => {
  return ARRAY_ERROR_CODE_SECURITY_INSPECTION_FAIL.includes(
    props.build?.vaccine?.errorCode?.toString() || ''
  );
});

const canOpenSecurityManual = computed(() => {
  return isProductWritable.value && isSecurityInspectionFailError.value;
});

const openSecurityManual = () => {
  showSecurityManual.value = true;
};

const deleteBuild = async () => {
  if (!(await checkProductPermission())) {
    return;
  }

  await showDialog({
    component: ConfirmDeleteBuild,
    props: {
      class: 'max-w-[31.2rem]',
      build: props.build
    },
    severity: 'info'
  });
};

const handleUploadBuildClick = async () => {
  if (!(await checkProductPermission())) {
    return;
  }
  if (!selectedGroupId.value && !props.build.gameId) {
    return;
  }

  handleUploadBuild({
    selectedGroupId: selectedGroupId.value,
    accessToken: accessToken.value,
    gameId: props.build.gameId,
    langCd: locale.value,
    buildId: props.build.buildId,
    nationCd: gdsInfo.value.nation
  });
};

const getProgressMessageSecurity = computed(() => {
  const step = props.build?.vaccine?.currentStep;
  return renderValueMessageSecurity(step);
});

const getProgressMessageBuild = computed(() => {
  const step = props.build?.build?.currentStep;
  return renderValueMessageBuild(step);
});

// Case Security Inspection Failed
const getMessageBuildCreateAndSuccess = computed(() => {
  const buildStatus = props.build?.build?.status;
  const buildStep = props.build?.build?.currentStep;
  if (!buildStatus || buildStep === null || buildStep === undefined) {
    return {
      buildTitle: '',
      content: {
        message: '',
        displayStep: 0,
        className: '',
        totalSteps: 7
      }
    };
  }

  let buildTitle = '';
  let content = getProgressMessageBuild.value;

  if (buildStatus !== UPLOAD_STATUS_MESSAGE.FAIL && buildStatus === UPLOAD_STATUS_MESSAGE.SUCCESS) {
    buildTitle = 'studio.prj_prod.this_prod.product_data.build.home_upload_status_success_title';
    content = {
      message: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_success_msg',
      displayStep: 7,
      className: '',
      totalSteps: 7
    };
  } else if (
    buildStatus !== UPLOAD_STATUS_MESSAGE.FAIL &&
    buildStatus !== UPLOAD_STATUS_MESSAGE.SUCCESS
  ) {
    buildTitle =
      'studio.prj_prod.this_prod.product_data.build.home_upload_status_testing_pop_build_status_creating_msg';
  }

  return {
    buildTitle,
    content
  };
});

// Case Build Creation Failed
const getMessageSecurityInspectionCreateAndSuccess = computed(() => {
  const securityStatus = props.build?.vaccine?.status;
  const securityStep = props.build?.vaccine?.currentStep;
  if (!securityStatus || securityStep === null || securityStep === undefined) {
    return {
      securityTitle: '',
      content: {
        message: '',
        displayStep: 0,
        className: '',
        totalSteps: 4
      }
    };
  }

  let securityTitle = '';
  let content = getProgressMessageSecurity.value;

  if (
    securityStatus !== UPLOAD_STATUS_MESSAGE.FAIL &&
    securityStatus === UPLOAD_STATUS_MESSAGE.SUCCESS
  ) {
    securityTitle =
      'studio.prj_prod.this_prod.product_data.build.home_upload_status_inspection_success_title';
    content = {
      message: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_success_msg',
      displayStep: 4,
      className: '',
      totalSteps: 4
    };
  } else if (
    securityStatus !== UPLOAD_STATUS_MESSAGE.FAIL &&
    securityStatus !== UPLOAD_STATUS_MESSAGE.SUCCESS
  ) {
    securityTitle =
      'studio.prj_prod.this_prod.product_data.build.home_upload_status_testing_pop_security_test_msg';
  }

  return {
    securityTitle,
    content
  };
});

const toggleDetails = () => {
  showDetails.value = !showDetails.value;
};
</script>
