<template>
  <div
    v-if="builds.length < 2 && !isCloseGuidFirstBuild"
    class="flex items-center gap-8 px-[3rem] py-24 bg-[#E8FC68] rounded-[2.8rem] shadow-[0_.6rem_1.4rem_0_rgba(0,51,150,.07)] [&+.mt-40]:!mt-24"
  >
    <div class="flex-1 flex items-center gap-8 text-on-surface-elevation-2">
      <s-icon size="3xl" icon="ic-v2-state-info-circle-line" class="shrink-0" />
      <p class="text-md font-medium leading-sm">
        {{ $t('studio.prj_prod.this_prod.product_data.build_msg') }}
      </p>
    </div>
    <s-button size="sm" variant="secondary" class="shrink-0" @click="goToSettings">
      {{ $t('studio.prj_prod.this_prod.product_data.build_redirect_execution_btn') }}
    </s-button>
    <button type="button" class="shrink-0 ml-8" @click="closeNotification">
      <s-icon
        size="3xl"
        icon="ic-v2-control-close-line"
        srOnlyText="Close"
        class="text-on-surface-elevation-3"
      />
    </button>
  </div>

  <st-box :class="{ 'mt-40': builds.length < 2 }">
    <div class="flex items-center gap-8">
      <div class="flex-1 flex items-center gap-8">
        <h3 class="text-2xl font-bold leading-xl text-on-surface-elevation-2">
          {{
            $t('studio.prj_prod.this_prod.product_data.build.details_select_file_pop_total_file')
          }}
        </h3>
        <em class="text-3xl font-bold leading-sm text-brand-primary">{{ localTotalElements }}</em>
      </div>

      <div class="flex flex-row items-center gap-16">
        <button
          type="button"
          class="text-on-surface-elevation-2 disabled:text-disabled-variant-1"
          @click="handleRefresh"
        >
          <s-icon icon="ic-v2-control-refresh-line" size="3xl" class="flex" srOnly="새로고침" />
        </button>
        <storage-capacity class="shrink-0" />
      </div>
    </div>

    <!--  빌드 업로드 목록 -->
    <div class="mt-24">
      <st-table
        :data="localBuilds"
        :columns="columns"
        :paging="{ total: localTotalElements, pageSize: PAGE_SIZE_BUILD, show: true }"
        @pageChange="handlePageChange"
      >
        <template #row="{ row: build }">
          <st-tr
            class="cursor-pointer hover:bg-interaction-hover"
            @click="$emit('rowClick', build)"
          >
            <st-td aLeft>
              <p class="font-bold">{{ build.buildNo }}</p>
              <p>{{ build.buildDescription || '-' }}</p>
            </st-td>
            <st-td aLeft @click.stop>
              <upload-status :status="getUploadStatus(build)" :build="build" @refresh="handleRefresh" />
            </st-td>
            <st-td v-if="productType !== SERVER_PRODUCT_TYPE.CONTENTS" aLeft>
              {{ build.integrityNumber !== null ? build.integrityNumber.toString() : '-' }}
            </st-td>
            <st-td aLeft>
              {{ formatFileSize(build.releaseSize) }}
            </st-td>
            <st-td aLeft>
              <p class="leading-sm">
                {{ getDateTimeByLocale(build.createdAt) }}
              </p>
              <p class="leading-sm">({{ getDateTimeByLocale(build.modifiedAt) }})</p>
            </st-td>
            <st-td aLeft>
              <span
                :class="{ 'text-placeholder': releaseStatusBuild(build.releaseStatus) === '-' }"
              >
                {{ releaseStatusBuild(build.releaseStatus) }}
              </span>
            </st-td>
            <st-td onlyButton>
              <button
                type="button"
                class="inline-flex"
                :class="{
                  'text-disabled-variant-1': !canEdit || isDeleteButtonDisabled(build),
                  'text-on-surface-elevation-2 hover:text-brand-primary':
                    canEdit && !isDeleteButtonDisabled(build)
                }"
                @click.stop="handleDelete(build, canEdit)"
              >
                <s-icon size="xl" icon="ic-v2-object-delete-line" />
              </button>
            </st-td>
          </st-tr>
        </template>
      </st-table>
    </div>
  </st-box>

  <!-- 실행 테스트 가이드 -->
  <div
    v-if="builds.length < 2 && !isCloseGuidFirstBuild"
    class="flex gap-24 mt-[10rem] pt-40 border-t-2 border-solid border-gray400"
  >
    <div class="flex-1 flex flex-col mt-[3rem]">
      <h3 class="mb-[2.2rem] text-4xl font-bold leading-3xl text-on-surface-elevation-2">
        {{ $t('studio.prj_prod.this_prod.product_data.build_guide2') }}
      </h3>
      <safe-html
        tag="p"
        class="text-xl leading-xl text-on-surface-elevation-3"
        :html="$t('studio.prj_prod.this_prod.product_data.build_guide1')"
      />
      <s-button size="md" variant="secondary" class="self-start mt-40" @click="openClientDownload">
        {{ $t('studio.prj_prod.this_prod.product_data.build_client_download_btn') }}
      </s-button>
    </div>
    <img
      :src="localizedImagePath"
      alt=""
      class="shrink-0 w-[46.6rem] h-[24.6rem] my-12 object-contain"
    />
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import StorageCapacity from '@/components/build/list-table/storage-capacity.vue';
import UploadStatus from '@/components/build/list-table/upload-status.vue';
import SafeHtml from '@/components/common/safe-html.vue';
import StBox from '@/components/common/st-box.vue';
import StTable from '@/components/common/table/st-table.vue';
import StTd from '@/components/common/table/st-td.vue';
import StTr from '@/components/common/table/st-tr.vue';
import { useRefreshHandler } from '@/composables/useRefreshHandler';
import {
  BUILD_KEY_NAME_COLUMN,
  type LocaleType,
  LOCALIZED_IMAGES,
  PAGE_SIZE_BUILD
} from '@/constants/build.const';
import { ONSTOVE_URL } from '@/constants/common.const';
import { DEFAULT_LOCALE } from '@/constants/locale.const';
import { SERVER_PRODUCT_TYPE } from '@/constants/products.const';
import { PRODUCT_LAUNCH_SETTING_PAGE_URL } from '@/constants/url.const';
import useProductStore from '@/stores/product.store';
import type { Build } from '@/types/build/build.type';
import type { ColumnSorting } from '@/types/table.type';
import {
  getLocalizedImagePath,
  getUploadStatus,
  handleDelete,
  isDeleteButtonDisabled,
  releaseStatusBuild
} from '@/utils/build.util';
import { redirectTo } from '@/utils/common.util';
import { getDateTimeByLocale } from '@/utils/date.util';
import { formatFileSize } from '@/utils/file.util';

const route = useRoute();
const productStore = useProductStore();

const isCloseGuidFirstBuild = ref(false);
const { product, isProductWritable } = storeToRefs(productStore);
const productType = computed(() => product.value?.productType);

const productId = route.params.productId as string;

const props = defineProps<{
  builds: Build[];
  totalElements: number;
}>();

const localBuilds = computed(() => props.builds);
const localTotalElements = computed(() => props.totalElements);

const { locale, t } = useI18n();

const BUILD_NOTIFICATION_KEY = 'studio:build:notifications';
const MAX_AGE = 3 * 30 * 24 * 60 * 60 * 1000; // 3 months

// eslint-disable-next-line func-call-spacing
const emit = defineEmits<{
  (e: 'rowClick', build: Build): void;
  (e: 'refresh'): void;
  (e: 'pageChange', page: number): void;
  (e: 'sortChange', value: ColumnSorting): void;
}>();

const { handleRefresh } = useRefreshHandler(() => emit('refresh'));

const columns = computed(() => {
  if (
    productType.value === SERVER_PRODUCT_TYPE.GAME ||
    productType.value === SERVER_PRODUCT_TYPE.UTILITY
  ) {
    return COLUMN_GAME_UTILITY;
  }
  return COLUMN_CONTENT;
});

const canEdit = computed(() => {
  return isProductWritable.value;
});

const goToSettings = () => {
  closeNotification();
  // Redirect to setting menu (26-1-2)
  redirectTo(PRODUCT_LAUNCH_SETTING_PAGE_URL);
};

const getNotifications = (): Record<string, { value: boolean; timestamp: number }> => {
  const notifications = localStorage.getItem(BUILD_NOTIFICATION_KEY);
  return notifications ? JSON.parse(notifications) : {};
};

const setNotification = (productId: string, value: boolean) => {
  const notifications = getNotifications();
  notifications[productId] = {
    value,
    timestamp: Date.now()
  };
  localStorage.setItem(BUILD_NOTIFICATION_KEY, JSON.stringify(notifications));
};

const cleanupOldNotifications = () => {
  const notifications = getNotifications();
  const now = Date.now();
  let hasChanges = false;

  Object.keys(notifications).forEach((key: string) => {
    if (now - notifications[key].timestamp > MAX_AGE) {
      delete notifications[key];
      hasChanges = true;
    }
  });

  if (hasChanges) {
    localStorage.setItem(BUILD_NOTIFICATION_KEY, JSON.stringify(notifications));
  }
};

const closeNotification = () => {
  isCloseGuidFirstBuild.value = true;
  setNotification(productId, true);
};

const openClientDownload = () => {
  window.open(`${ONSTOVE_URL}/download`, '_blank');
};

const handlePageChange = async (page: number) => {
  emit('pageChange', page);
};

onMounted(() => {
  cleanupOldNotifications();

  const notifications = getNotifications();
  isCloseGuidFirstBuild.value = notifications[productId]?.value === true;
});

const onSortChange = async (value: ColumnSorting) => {
  emit('sortChange', value);
};

const COLUMN_GAME_UTILITY = [
  {
    key: BUILD_KEY_NAME_COLUMN.BUILD_ID,
    title: 'studio.prj_prod.this_prod.product_data.build.home_build_id_header1',
    sortable: true,
    dataIndex: BUILD_KEY_NAME_COLUMN.BUILD_ID,
    sortKey: BUILD_KEY_NAME_COLUMN.BUILD_ID,
    width: 'auto',
    onSort: onSortChange
  },
  {
    key: BUILD_KEY_NAME_COLUMN.UPLOAD_STATUS,
    title: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_header2',
    sortable: false,
    dataIndex: BUILD_KEY_NAME_COLUMN.UPLOAD_STATUS,
    sortKey: '',
    width: '14rem'
  },
  {
    key: BUILD_KEY_NAME_COLUMN.INTEGRITY,
    title: 'studio.prj_prod.this_prod.product_data.build.home_integrity_header4',
    dataIndex: BUILD_KEY_NAME_COLUMN.INTEGRITY,
    width: '9.6rem'
  },
  {
    key: BUILD_KEY_NAME_COLUMN.SIZE,
    title: 'studio.prj_prod.this_prod.product_data.build.home_file_size_header5',
    sortable: true,
    dataIndex: BUILD_KEY_NAME_COLUMN.SIZE,
    sortKey: BUILD_KEY_NAME_COLUMN.SIZE,
    width: '9.2rem',
    onSort: onSortChange,
    isShowIcon: true,
    titleTooltip: t('studio.prj_prod.this_prod.product_data.build.home_build_storage_tooltip'),
    tooltipPlacement: 'top',
    distance: 6
  },
  {
    key: BUILD_KEY_NAME_COLUMN.DATE,
    title: 'studio.prj_prod.this_prod.product_data.build.home_edited_date_header6',
    sortable: true,
    dataIndex: BUILD_KEY_NAME_COLUMN.DATE,
    sortKey: BUILD_KEY_NAME_COLUMN.DATE,
    width: '15rem',
    onSort: onSortChange,
    titleTooltip: `${t('studio.build_run_test.build_info7_reg_date')} (${t('studio.prj_prod.this_prod.product_data.build.details_basic_info_modified_date_row5')})`
  },
  {
    key: BUILD_KEY_NAME_COLUMN.RELEASE_STATUS,
    title: 'studio.prj_prod.this_prod.product_data.build.home_prod_status_header7',
    dataIndex: BUILD_KEY_NAME_COLUMN.RELEASE_STATUS,
    width: '9.2rem',
    titleTooltip: 'studio.gnb.common_2depth_plan'
  },
  {
    key: BUILD_KEY_NAME_COLUMN.ACTION,
    title: '',
    dataIndex: BUILD_KEY_NAME_COLUMN.ACTION,
    width: '4.8rem'
  }
];

const COLUMN_CONTENT = [
  {
    key: BUILD_KEY_NAME_COLUMN.BUILD_ID,
    title: 'studio.prj_prod.this_prod.product_data.build.home_build_id_header1',
    sortable: true,
    dataIndex: BUILD_KEY_NAME_COLUMN.BUILD_ID,
    sortKey: BUILD_KEY_NAME_COLUMN.BUILD_ID,
    width: 'auto',
    onSort: onSortChange
  },
  {
    key: BUILD_KEY_NAME_COLUMN.UPLOAD_STATUS,
    title: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_header2',
    sortable: false,
    dataIndex: BUILD_KEY_NAME_COLUMN.UPLOAD_STATUS,
    sortKey: '',
    width: '14rem'
  },
  {
    key: BUILD_KEY_NAME_COLUMN.SIZE,
    title: 'studio.prj_prod.this_prod.product_data.build.home_file_size_header5',
    sortable: true,
    dataIndex: BUILD_KEY_NAME_COLUMN.SIZE,
    sortKey: BUILD_KEY_NAME_COLUMN.SIZE,
    width: '9.2rem',
    onSort: onSortChange,
    isShowIcon: true,
    titleTooltip: t('studio.prj_prod.this_prod.product_data.build.home_build_storage_tooltip'),
    tooltipPlacement: 'top',
    distance: 6
  },
  {
    key: BUILD_KEY_NAME_COLUMN.DATE,
    title: 'studio.prj_prod.this_prod.product_data.build.home_edited_date_header6',
    sortable: true,
    dataIndex: BUILD_KEY_NAME_COLUMN.DATE,
    sortKey: BUILD_KEY_NAME_COLUMN.DATE,
    width: '15rem',
    onSort: onSortChange
  },
  {
    key: BUILD_KEY_NAME_COLUMN.RELEASE_STATUS,
    title: 'studio.prj_prod.this_prod.product_data.build.home_prod_status_header7',
    dataIndex: BUILD_KEY_NAME_COLUMN.RELEASE_STATUS,
    width: '9.2rem'
  },
  {
    key: BUILD_KEY_NAME_COLUMN.ACTION,
    title: '',
    dataIndex: BUILD_KEY_NAME_COLUMN.ACTION,
    width: '4.8rem'
  }
];

const localizedImagePath = computed(() => {
  return getLocalizedImagePath(
    LOCALIZED_IMAGES.CLIENT_RUN_TEST,
    locale.value as LocaleType,
    DEFAULT_LOCALE
  );
});
</script>
