import { ref } from 'vue';

export const useRefreshHandler = (refreshCallback: () => void, timeout: number = 500) => {
  const isRefreshing = ref(false);

  const handleRefresh = async () => {
    if (isRefreshing.value) {
      return;
    }

    isRefreshing.value = true;
    refreshCallback();
    setTimeout(() => {
      isRefreshing.value = false;
    }, timeout);
  };

  return {
    isRefreshing,
    handleRefresh
  };
};
